$primary-color: #f25f23;
$secondary-color: #3C3C3C;

$icon-color:$primary-color;
$header-background:#FFF;

$page-header-bkg:#fef8e1;
$page-header:$primary-color;

$toggle-icon-bar:white;
$line-color:#ededed;

$home-search-bkg:#fef8e1;
$home-search-text:$primary-color;
$home-search-submit-bkg:$primary-color;
$home-search-submit-txt:#FFF;


/* header and navbar */
header{
  background-color: $header-background;
  border-bottom: 10px solid $primary-color;
}

.navbar{
  margin-bottom:0;
  min-height:90px;
}
.navbar-brand {
  padding: 10px;
  img {
    max-width:160px;
  }
}
@media screen and (max-width: 1000px){
  .navbar-brand img{
    max-width:160px;
  }
}
@media screen and (max-width: 430px) {
  .navbar-brand img {
    max-width:140px;
  }
  .navbar {
    min-height:70px;
  }
}
.navbar-nav>li>a {
  padding-top:35px;
  color: $primary-color;
  text-transform:uppercase !important;
}
.nav>li>a:focus, .nav>li>a:hover{
  background-color : transparent;
}
.navbar-toggle {
  background-color:$primary-color;
  margin-right:0;

  .icon-bar {
    background-color: $toggle-icon-bar;
  }
}

/* body, main and basic styles */
body{
  color:#666;
  font:16px "Lato", sans-serif;
  line-height: 23px;
  word-wrap: break-word;
  font-weight: 300;
}
a{
  color:$primary-color;
}
a:hover, a:active{
  color:$secondary-color;
}
h1, h2, h3, h4, h5, h6{
  color : $secondary-color;
}
ul{
  list-style: none;
  -webkit-padding-start:0;
}
p {
  margin:0 0 20px;
}
input[type="text"], input[type="email"], input[type="search"], input[type="password"], input[type="tel"], input[type="url"], input[type="date"], textarea{
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  width: 100%;
  margin: 0 0 20px;
  background-color: #f9f9f9;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
input[type="reset"], input[type="button"], input[type="submit"]{
  background-color: $primary-color;
  border:1px solid $primary-color;
  color: #FFF;
  font-weight: 700;
  cursor: pointer;
  margin-bottom: 30px;
  text-transform: uppercase;
  display: block;
  text-align: center;
  height: 40px;
  padding: 0 20px;
  margin-top: 5px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
main {
  padding:30px 0 40px;
}
/* blog posts */
.post {
  padding: 0 20px 20px;
  margin-bottom: 40px;
  border-bottom: 4px double $line-color;
}

.meta {
  padding:10px;
  margin-bottom :10px;
  border-bottom:1px solid $line-color;
  font-size:13px;
  background-color:#fef8e1;
  i {
    color:$icon-color;
  }
}
.author, .date {
  padding:0 10px 0px 5px;
}
p.title {
  font-size: 30px;
  line-height: 37px;
  margin-bottom :0;
  padding-bottom:10px;
  border-bottom:1px solid $line-color;
}
.post-excerpt{
  padding: 0 0 20px;
  margin-bottom: 40px;
  border-bottom: 4px double $line-color;
  .icon{
    display:inline-block;
    float:left;
    width:10%;
    font-size:4rem;
    margin-right: 2%;
    text-align:center;
  }
  .excerpt{
    display:inline-block;
    width:88%;
  }
  a.button{
    font-weight: bold;
    text-transform: uppercase;
    color : $primary-color;
    border-bottom: 1px solid $primary-color;
    letter-spacing:.1em;
  }
}
.pagination {
  display: block;
  min-height: 50px;
  border-bottom: 1px solid $line-color;

  a {
    color: $secondary-color;
    border-bottom: 1px solid $secondary-color;
    letter-spacing: .1em;
  }
  a.right {
    display: inline-block;
    float: right;
  }
  a.left {
    display: inline-block;
    float: left;
  }
  a:hover {
    text-decoration: none;
    font-weight: bold;
  }

}
/* sidebar */
aside h3.widget-title, aside h3, aside h2.title {
  font-size: 20px;
  line-height: 23px;
  padding-top:0;
  padding-bottom: 15px;
  margin-bottom:20px;
  font-weight: normal;
  margin-top:0;
  color:#333;
  letter-spacing: 2px;
  text-transform: uppercase;
  border-bottom: 1px solid $line-color;
}
/* footer */
footer{
  background-color:#FFF;
  padding: 10px 0 10px;
  color:$secondary-color;
  font-size: 14px;
  line-height: 21px;
  text-align:center;
  .container{
    margin-left: auto;
    max-width: 85%;
  }
  a {
    display:none
  }
  .copyright {
    text-align:center;
  }
}

.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;

  a {
    display:block;
    background-color: $page-header-bkg;
    color: $secondary-color;
    width: 40px;
    height: 40px;
    border-radius: 3px;
    font-size: 16px;
    line-height: 40px;
    text-align: center;
  }
}
@media screen and (max-width:830px) {
  footer .copyright{
    padding-bottom: 10px;
    display: block;
  }
}
/*section specific code */
/*styles for page-header partial*/
section.page-title-wrap {
  background-color: $page-header-bkg !important;
  padding               : 10px 0 20px;
  text-align            : center;
  background-attachment : fixed;
  background-position   : center center;
  background-size       : cover;
  margin-top            : -1px;

  h1.page-title {
    font-size: 30px;
    line-height: 37px;
    color: $page-header;
    font-weight: 700;
    margin:0 auto;
  }
}
/* only needed when using the signup-message partial */
#home-intro{
  height:240px;
  text-align:center;
  background-image:url('/images/yellow-gradient.png');
  background-repeat: repeat-x;
  background-position: bottom;
  .promotional_bar_content{
    text-align:center;
    height:240px;
    background-image: url(/images/boost-your-life.png);
    background-repeat: no-repeat;
    background-position: left bottom;
    max-width: 1170px;
    margin: auto;
    h3, p, a{
      margin-left:30%;
    }
    h3{
      padding-top:50px;
      text-transform:uppercase;
      padding-bottom:10px;
      color:$primary-color;
    }
    p{
      padding-bottom:10px;
    }
    a{
      background-color: #f25f23;
      border: 1px solid #f25f23;
      color: white;
      padding: 10px 20px;
      border-radius: 3px;
    }
    @media screen and (max-width:1075px){
      background-position:center bottom;
      h3, p, a{
        display:none;
      }
    }
  }
}

/*home search only needed when including the search-jobs partial*/
section#home-search{
  text-align:center;
  border-top:5px solid $primary-color;
  background:$home-search-bkg;/* Old browsers */
  background: -moz-linear-gradient(top, #fae27b 0%, #ffffff 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #fae27b 0%,#ffffff 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #fae27b 0%,#ffffff 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fae27b', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
  padding: 20px 0 30px;
  h3{
    color:$home-search-text;
    padding-top: 0;
    font-size: 2em;
    font-weight: 700;
  }
  form{
    max-width: 75%;
    text-align: center;
    margin: auto;
    padding:0;
  }
  input[type="text"]{
    width:37%;
  }
  input[type="submit"]{
    background-color:$home-search-submit-bkg;
    border:1px solid $home-search-submit-bkg;
    color:$home-search-submit-txt;
    display:inline-block;
    height:45px;
  }
}
@media screen and (max-width:782px) {
  section#home-search form {
    max-width : 90%;
    input[type="text"]{
      width:100%;
    }
  }
}
/*by-sector, sectors only needed when including sector-module partial in sidebar
by-location, locations only needed when including city-module partial in sidebar*/
aside .by-sector h3, aside .by-location h3, {
  font-size: 16px;
  line-height: 23px;
  padding-bottom: 15px;
  font-weight: normal;
  margin-top: 0;
  color: #333;
  letter-spacing: 2px;
  text-transform: uppercase;
}
aside ul.sectors li, aside ul.locations li {
  flex-basis: 100%;
  padding-bottom:5px;
  a i{
    color: $icon-color !important;
  }
}

/* site specific before_footer*/
.before-footer{
  background-color:#FFF;
  color:#333;
  padding:30px 0 10px;
  font-size:12px;
  text-align: center;
  margin:auto;
  img{
    max-width:120px;
    display: block;
    text-align: center;
    margin: 5px auto 10px;
  }
  a{
    color:#333;
    font-weight:400;
    font-size:13px;
    padding:5px;
  }

}

/*sidebar listings*/
.jobs {
  padding: 0;
  h2.Title {
    font-size: 17px;
    max-height: 20px;
    overflow: hidden;
    margin-bottom:5px;
  }
  a.Company{
    color: $secondary-color;
  }
  .TimePosted {
    display: block;
    color:#999;
    font-size:11px;
  }
  .snippet {
    display: none;
  }
}